.swiper-button-next,
.swiper-button-prev {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: '' !important;
}

.swiper-button-disabled {
  opacity: 0.5 !important;
}
